<template>
    <div class="admin-menu" v-loading="loading">
        <div class="el-container" v-if="menuInfo.length>0">
            <el-aside class="menu">
                <el-menu
                    default-active="0"
                    class="el-menu-vertical"
                >
                    <el-menu-item
                        v-for="(item,index) in menuInfo"
                        :key="index"
                        @click="handleClickMenu(index)"
                        :index="index+''"
                    >
                        <i :class="['iconfont',item.Icon||'']" style="padding-right: 0.03rem"></i>
                        <span slot="title"> {{ item.Name }}</span>
                    </el-menu-item>
                </el-menu>
            </el-aside>
            <el-main class="content" ref="domMainContent">
                <div class="menuHeadBox" ref="domHeadBox">
                    <table ref="menuHeadTable">
                        <thead>
                            <tr>
                                <th rowspan="2" class="td1">
                                    <div class="cell">
                                        二级菜单
                                    </div>
                                </th>
                                <th rowspan="2" class="td2">
                                    <div class="cell">
                                        三级菜单
                                    </div>
                                </th>
                                <th :colspan="menuInfo[curMenuIndex].RoleViews.length" class="roles-td">
                                    管理角色
                                </th>
                            </tr>
                            <tr>
                                <th v-for="role in menuInfo[curMenuIndex].RoleViews" :key="role.name">
                                    <div class="cell check-cell">
                                        {{ role.Name }}
                                    </div>
                                </th>
                            </tr>
                        </thead>
                    </table>
                </div>

                <div class="content-wrapper" ref="domContentWrapper">
                    <div class="list" ref="domList">
                        <table
                            cellpadding="0"
                            cellspacing="0"
                        >
                            <tbody>
                                <template v-for="(item,index1) in menuInfo[curMenuIndex].Child">
                                    <tr :key="'menu'+item.Id">
                                        <th
                                            :colspan="2+(menuInfo[curMenuIndex].RoleViews.length||1)"
                                            class="handle-col handle-row"
                                            v-show="!showMapping[item.Id] && item.Child.length>1"
                                        >
                                            <a class="show-or-hide hide" @click="handleShowRow(item)">显示 <i class="iconfont">&#xe6a1;</i></a>
                                            <span class="name">{{ item.Name }}</span>
                                        </th>
                                    </tr>
                                    <template>
                                        <template v-for="(lastMenu,lastIndex) in item.Child">
                                            <tr
                                                v-show="showMapping[item.Id]"
                                                :key="'lastMenu'+lastMenu.Id"
                                                :class="{
                                                    trlineTop:lastIndex==0&&index1!=item.Child.length-1,
                                                    trlineLast:lastIndex==item.Child.length-1&&index1!=item.Child.length-1
                                                }"
                                            >
                                                <th
                                                    v-if="lastIndex==0"
                                                    :rowspan="item.Child.length"
                                                    class="handle-col td1"
                                                >
                                                    <a
                                                        class="show-or-hide show"
                                                        v-if="item.Child.length>1"
                                                        @click="handleShowRow(item)"
                                                    >收起 <i class="iconfont">&#xe6a2;</i></a>
                                                    <span class="menu1-cell cell">{{ item.Name }}</span>
                                                </th>
                                                <td class="td2">
                                                    <div v-if="item.Id!==lastMenu.Id" class="menu2-cell cell" :title="lastMenu.Name">
                                                        <el-checkbox
                                                            :indeterminate="indeterminateMapping[lastMenu.Id]"
                                                            v-model="checkallMapping[lastMenu.Id]"
                                                            @change="checked=>checkRowAllChange(checked,lastMenu,menuInfo[curMenuIndex].RoleViews)"
                                                        >
                                                            {{ lastMenu.Name }}
                                                        </el-checkbox>
                                                    </div>
                                                </td>
                                                <td
                                                    class="check-td"
                                                    v-for="(role) in menuInfo[curMenuIndex].RoleViews"
                                                    :key="'check-td'+role.Id"
                                                    @click.self="handleChange(role.Id,lastMenu)"
                                                >
                                                    <div class="cell check-cell">
                                                        <el-checkbox-group
                                                            v-model="checkMapping[lastMenu.Id]"
                                                            @change="checkeds=>handleChangeGroup(checkeds,lastMenu)"
                                                        >
                                                            <el-checkbox :label="role.Id" :key="'role'+role.Id" class="no-show-label"></el-checkbox>
                                                        </el-checkbox-group>
                                                    </div>
                                                </td>
                                                <td v-if="!menuInfo[curMenuIndex].RoleViews.length"></td>
                                            </tr>
                                        </template>
                                    </template>
                                </template>
                            </tbody>
                        </table>
                    </div>
                    <div class="footer" ref="footerBtns">
                        <el-button
                            class="save-btn"
                            type="primary"
                            @click="handleSave"
                            :loading="btnLoading"
                        >
                            保存设置
                        </el-button>
                    </div>
                </div>
            </el-main>
        </div>
    </div>
</template>

<script>

export default {
    name: 'admin-menu',
    data() {
        return {
            loading: false,
            curMenuIndex: 0, // 选中得菜单下标
            menuInfo: [], // 菜单信息
            indeterminateMapping: {}, // 不确定状态映射
            checkallMapping: {}, // 全选映射
            checkMapping: { }, // 菜单勾选映射 key:菜单 val:角色id
            showMapping: {}, // 展开收起控制
            btnLoading: false,
        };
    },
    created() {
        this.init();
    },
    methods: {
        async init() {
            this.getMenuBind();
        },
        handleClickMenu(index) {
            this.curMenuIndex = index;
            this.initChangeData();
        },
        // 获取菜单信息
        async getMenuBind() {
            this.loading = true;
            const data = await this.$axios.get('/interfaceApi/basicplatform/usermenu/get_menu_bind');
            this.menuInfo = data || [];
            if (this.menuInfo.length > 0) {
                this.initChangeData();
            }
            this.loading = false;
        },
        // 切换菜单初始化数据
        initChangeData() {
            const indeterminateMapping = {};
            const checkallMapping = {};
            const checkMapping = {};
            const showMapping = {};
            const curMenuInfo = this.menuInfo[this.curMenuIndex];
            curMenuInfo.Child.forEach(menu2 => {
                if (menu2.Child.length === 0) {
                    menu2.Child.push(menu2);
                }
                menu2.Child.forEach(menu3 => {
                    indeterminateMapping[menu3.Id] = false;
                    checkallMapping[menu3.Id] = false;
                    checkMapping[menu3.Id] = [];
                });
                if (menu2.Child.length === 1) {
                    showMapping[menu2.Id] = true;
                } else {
                    showMapping[menu2.Id] = false;
                }
            });
            curMenuInfo.RoleViews.forEach(role => {
                role.MenuViews.forEach(roleMenu => {
                    if (!checkMapping[roleMenu.Id]) {
                        checkMapping[roleMenu.Id] = [];
                    }
                    if (!checkMapping[roleMenu.Id].includes(role.Id)) {
                        checkMapping[roleMenu.Id].push(role.Id);
                    }
                    if (checkMapping[roleMenu.Id].length > 0) {
                        if (checkMapping[roleMenu.Id].length === curMenuInfo.RoleViews.length) {
                            checkallMapping[roleMenu.Id] = true;
                        } else {
                            indeterminateMapping[roleMenu.Id] = true;
                        }
                    }
                });
            });
            this.indeterminateMapping = indeterminateMapping;
            this.checkallMapping = checkallMapping;
            this.checkMapping = checkMapping;
            this.showMapping = showMapping;


            // 默认展开第一行
            if (curMenuInfo.Child.length > 0) {
                this.handleShowRow(curMenuInfo.Child[0], true);
            }
            this.domListResize();
        },
        // 处理当出现滚动条 固定表头不能和表格对齐
        domListResize() {
            this.$nextTick(() => {
                try {
                    const domList = this.$refs.domList;
                    const domHeadBox = this.$refs.domHeadBox;
                    const domMainContent = this.$refs.domMainContent.$el;
                    const domContentWrapper = this.$refs.domContentWrapper;
                    const footerBtns = this.$refs.footerBtns;
                    this.$refs.domHeadBox.style.height = this.$refs.menuHeadTable.style.height;
                    domContentWrapper.style.height = domMainContent.offsetHeight - domHeadBox.offsetHeight + 'px';
                    if (domMainContent.offsetHeight - domHeadBox.offsetHeight - footerBtns.offsetHeight < domList.offsetHeight) {
                        domList.classList.add('haveScroll');
                    } else {
                        domList.classList.remove('haveScroll');
                    }
                    return null;
                } catch (e) {
                    return null;
                }
            });
        },
        // 选择、取消选择 整行
        checkRowAllChange(val, menu, roles) {
            if (val) {
                this.checkMapping[menu.Id] = roles.map(role => role.Id);
                this.indeterminateMapping[menu.Id] = !(this.checkMapping[menu.Id].length === roles.length);
                this.checkallMapping[menu.Id] = true;
            } else {
                this.checkMapping[menu.Id] = [];
                this.indeterminateMapping[menu.Id] = false;
                this.checkallMapping[menu.Id] = false;
            }
        },
        handleChangeGroup(vals, menu) {
            if (vals.length > 0) {
                if (vals.length === this.menuInfo[this.curMenuIndex].RoleViews.length) {
                    this.indeterminateMapping[menu.Id] = false;
                    this.checkallMapping[menu.Id] = true;
                } else {
                    this.indeterminateMapping[menu.Id] = true;
                    this.checkallMapping[menu.Id] = false;
                }
            } else {
                this.indeterminateMapping[menu.Id] = false;
                this.checkallMapping[menu.Id] = false;
            }
        },
        // 保存
        async handleSave() {
            this.btnLoading = true;
            const subData = this.formatFormData();
            const data = await this.$axios.post('/interfaceApi/basicplatform/usermenu/save_menu_bind', subData);
            if (data) {
                this.$message.success('保存成功');
                this.init();
            }
            this.btnLoading = false;
        },

        // 格式化保存数据
        formatFormData() {
            // 保存得是最后一级菜单id，后端接口需要父级id 处理下
            const curMenuInfo = this.menuInfo[this.curMenuIndex];
            const menuParentMap = {};
            curMenuInfo.Child.forEach(menu2 => {
                menu2.Child.forEach(menu3 => {
                    if (menu2.Id !== menu3.Id) {
                        menuParentMap[menu3.Id] = [ { Id: curMenuInfo.Id }, { Id: menu2.Id }];
                    } else {
                        menuParentMap[menu3.Id] = [ { Id: curMenuInfo.Id }];
                    }
                });
            });

            const roleCheckMapping = {};
            for (const menuId in this.checkMapping) {
                this.checkMapping[menuId].forEach(roleId => {
                    if (!roleCheckMapping[roleId]) {
                        roleCheckMapping[roleId] = [];
                    }
                    if (menuParentMap[menuId]) {
                        roleCheckMapping[roleId].push(...menuParentMap[menuId]);
                    }
                    roleCheckMapping[roleId].push({ Id: menuId });
                });
            }

            curMenuInfo.RoleViews.forEach(role => {
                role.MenuViews = roleCheckMapping[role.Id];
            });
            return {
                Id: this.menuInfo[this.curMenuIndex].Id,
                RoleViews: curMenuInfo.RoleViews,
            };
        },


        // 展示折叠当前行
        handleShowRow(item, show) {
            if (show) {
                this.showMapping[item.Id] = show;
            } else {
                this.showMapping[item.Id] = !this.showMapping[item.Id];
            }
            this.domListResize();
        },

        // 点击表格td 选中、取消
        handleChange(roleId, lastMenu) {
            const index = this.checkMapping[lastMenu.Id].indexOf(roleId);
            if (index !== -1) {
                this.checkMapping[lastMenu.Id].splice(index, 1);
            } else {
                this.checkMapping[lastMenu.Id].push(roleId);
            }
            this.handleChangeGroup(this.checkMapping[lastMenu.Id], lastMenu);
        },
    },
};
</script>

<style lang="stylus" scoped>
.admin-menu
    width 100%
    height 100%
    padding: 0.2rem;
    background: #E1E6EC;
    .el-container
        background #fff;
        padding-top 0.08rem;
        height 100%;
        >>> .el-aside
            width 1.75rem !important;
            margin-right 0.1rem;
            overflow-x hidden
            overflow-y auto
        .el-menu
            width 1.6rem;
            background: #f4f7fd;
            border: 0.02rem solid #FFFFFF;
            border-left 0;
            .el-menu-item
                width: 1.6rem;
                height: 0.6rem;
                display: flex;
                align-items: center;
                font-size: 0.16rem;
                color: #666666;
                border-bottom: 0.02rem solid #FFFFFF;
                &.last-child
                    border-bottom 0
                .el-icon-menu
                    font-size: 0.16rem;
                &.is-active
                    background: linear-gradient(270deg, #55A7FE 0%, #3E79C5 100%);
                    box-shadow: 0.02rem 0.02rem 0.08rem 0rem rgba(0, 0, 0, 0.4);
                    border-radius: 0.02rem;
                    color #fff
                    border 0
        .content
            width 100%
            height 100% !important;
            position relative;
            overflow hidden
            background #fff!important;
            /*position relative*/
            .content-wrapper
                width 100%;
                height 100%;
                overflow-y auto;
                overflow-x hidden;
            .menuHeadBox
                width calc(100% - 9px);
                background #fff;
                position relative
                z-index 9;
            table
                width: 100%;
                table-layout fixed
                border-collapse collapse
                border:none;
                thead
                    background: #EDF0F5;
                    th
                        text-align center!important
                tr
                    th,td
                        padding 0.1rem 0;
                        text-align center
                        font-size: 0.16rem;
                        color #333333
                        border 0.01rem solid #D7D7D7
                        border-collapse collapse
                    th
                        color #022782
                    td
                        background: #fff;
                        white-space nowrap
                        .no-show-label
                            /deep/ .el-checkbox__label
                                display none
                    .td1
                        width 1.35rem;
                        padding 0
                    .td2
                        width 2rem;
                        padding: 0.1rem 0;
                    .cell
                        min-width: 0.16rem;
                        display inline-block
                        text-align center
                        &.check-cell
                            width 0.16rem;
                    .menu1-cell
                        width 1.29rem;
                        text-align left
                        padding: 0.1rem 0;
                        padding-left 0.15rem;
                    .menu2-cell
                        width: 1.98rem;
                        overflow hidden
                        text-overflow ellipsis
                        white-space nowrap
                        text-align left
                        padding-left 0.2rem;
                        .el-checkbox
                            display flex
                            align-items center;
                        /deep/ .el-checkbox
                            display flex
                            align-items center;
                        .el-checkbox__label
                            max-width: 1.6rem;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        /deep/ .el-checkbox__label
                            max-width: 1.6rem;
                            overflow: hidden;
                            text-overflow: ellipsis;
                    .check-td
                        cursor pointer
                .handle-col
                    position relative
                    text-align left
                    .iconfont
                        font-size 0.1rem !important;
                    .show-or-hide
                        display block
                        width: 0.6rem;
                        height: 0.24rem;
                        line-height 0.24rem;
                        background: #F0F0F0;
                        border-radius: 0.02rem;
                        text-align center
                        font-size 0.12rem;
                        color #666666
                        cursor pointer
                        &.show
                            position absolute
                            left: 0.1rem;
                            top: 0.06rem;
                        &.hide
                            position absolute
                            right 0.2rem;
                            top 0.1rem;
                .handle-row
                    border 0.05rem solid #EDF0F6
                    border-left-width 0.01rem;
                    border-right-width 0.01rem;
                    margin 0.02rem 0;
                    padding-left 0.15rem;
                .trlineTop
                    th,td
                        border-top 0.05rem solid #EDF0F6
                    th
                        border-bottom 0
                .trlineLast
                    td
                        border-bottom 0
            .list
                width calc(100% - 9px);
                box-sizing: unset;
                /*min-width  calc(100% + 9px);*/
                /*height calc(100% - 3rem);*/
                /*overflow-y scroll*/
                /*overflow-x hidden*/
                &.haveScroll
                    width 100%;
                table
                    table-layout auto
            .footer
                width 100%
                padding 0.3rem 0
                box-shadow: 0.01rem -0.01rem 0.03rem rgba(133,133,133,0.2);
                /*position: absolute;*/
                /*left: 0.2rem;*/
                /*bottom: 0.02rem;*/
                background #fff;
                padding-left 0.2rem;
</style>
